import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SlackBlockEditComponent } from './slack-block-edit/slack-block-edit.component';
import { SlackBlockPreviewComponent } from './slack-block-preview/slack-block-preview.component';
import { SlackBlocksComponent } from './slack-blocks.component';
import { SlackSurveyPreviewComponent } from './slack-survey-preview/slack-survey-preview.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, SlackBlockPreviewComponent, SlackBlockEditComponent],
  declarations: [SlackBlocksComponent, SlackSurveyPreviewComponent],
  exports: [SlackBlocksComponent],
})
export class SlackBlocksModule {}
