<div>
  <span *ngIf="error">{{ error }}</span>
  <ng-container [ngSwitch]="block.type">
    <ng-container *ngSwitchCase="'section'">
      <ion-list class="slack-block-form">
        <ion-item height="20px">
          <angular-editor
            [(ngModel)]="htmlStrings[0]"
            [config]="editorConfig"
            style="width: 100%"
            (ngModelChange)="onSectionHtmlChange($event)"
          >
          </angular-editor>
          <ion-button
            color="light"
            *ngIf="block.accessory"
            slot="end"
            (click)="addImageAccessory()"
            class="section-image-accessory"
          >
            <ion-icon name="image-outline" class="padded-icon"></ion-icon>
            Choose photo
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-button color="dark" fill="clear" class="button-dark-border" (click)="toggleImageAccessory()">
            <ion-icon [name]="block.accessory ? 'checkmark-circle' : 'ellipse-outline'" class="padded-icon"></ion-icon>
            Show accessory image
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-container>

    <ng-container *ngSwitchCase="'actions'">
      <ion-list
        class="slack-block-form"
        *ngFor="let button of block.elements; let i = index"
        style="border-bottom: 1px solid var(--ion-color-step-100)"
      >
        <ion-item>
          <ion-label position="stacked">Button label (max 30 characters)</ion-label>
          <ion-input [(ngModel)]="button.text.text" type="text" inputmode="url" [maxlength]="30" required></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Link URL</ion-label>
          <ion-input [(ngModel)]="button.url" type="url" [maxlength]="3000" required></ion-input>
        </ion-item>
        <ion-item>
          <div slot="start">
            <ion-button fill="clear" color="dark" [disabled]="true" class="btn-small"> Style: </ion-button>
            <ion-button
              fill="clear"
              color="dark"
              (click)="button.style = 'default'"
              class="btn-small"
              [ngClass]="{ 'pill-button-active': !button.style || button.style === 'default' }"
            >
              Default
            </ion-button>
            <ion-button
              fill="clear"
              color="dark"
              (click)="button.style = 'primary'"
              class="btn-small"
              [ngClass]="{ 'pill-button-active': button.style === 'primary' }"
            >
              Green
            </ion-button>
          </div>
          <ion-button
            slot="end"
            color="light"
            class="btn-small"
            *ngIf="block.elements.length > 1"
            (click)="removeElement(i)"
          >
            Remove
          </ion-button>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item>
          <ion-button color="light" (click)="addButtonElement()"> + Add button </ion-button>
        </ion-item>
      </ion-list>
    </ng-container>

    <ng-container *ngSwitchCase="'image'">
      <ion-list class="slack-block-form">
        <!-- <ion-item>
          <ion-label position="stacked">URL</ion-label>
          <ion-input [(ngModel)]="block.image_url" type="url" inputmode="url" [maxlength]="3000" required></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Alt text</ion-label>
          <ion-input [(ngModel)]="block.alt_text" type="text" [maxlength]="2000" required></ion-input>
        </ion-item> -->
        <ion-item>
          <ion-button (click)="addImageAccessory()" color="light"> Upload image </ion-button>
          <ion-button (click)="addGif()" color="light"> Choose a GIF </ion-button>
        </ion-item>
      </ion-list>
    </ng-container>

    <ng-container *ngSwitchCase="'divider'"></ng-container>

    <ng-container *ngSwitchCase="'header'">
      <ion-list class="slack-block-form">
        <ion-item>
          <ion-label position="stacked">Text</ion-label>
          <ion-input [(ngModel)]="block.text.text" type="text" [maxlength]="150" required></ion-input>
        </ion-item>
      </ion-list>
    </ng-container>

    <ng-container *ngSwitchCase="'context'">
      <ion-list class="slack-block-form">
        <div *ngFor="let element of block.elements; let i = index">
          <ion-card-header style="padding-top: 5px; padding-bottom: 5px">
            <div class="flex-space-between">
              <div class="flexed-align-center">
                <strong>{{ getElementTitle(element) }}</strong>
              </div>
              <span>
                <ion-button fill="clear" (click)="removeElement(i)" *ngIf="block.elements.length > 1">
                  <ion-icon name="trash-outline"></ion-icon>
                </ion-button>
              </span>
            </div>
          </ion-card-header>
          <div>
            <ion-list class="slack-block-form">
              <ng-container *ngIf="element.type === 'image'">
                <ion-item>
                  <ion-label position="stacked">URL</ion-label>
                  <ion-input [(ngModel)]="element.image_url" type="url" inputmode="url" [maxlength]="3000" required>
                  </ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="stacked">Alt text</ion-label>
                  <ion-input [(ngModel)]="element.alt_text" type="text" [maxlength]="2000" required></ion-input>
                </ion-item>
              </ng-container>
              <ng-container *ngIf="element.type !== 'image'">
                <ion-item>
                  <angular-editor
                    [(ngModel)]="htmlStrings[i]"
                    [config]="editorConfigContext"
                    style="width: 100%"
                    (ngModelChange)="onContextHtmlChange(i, $event)"
                  >
                  </angular-editor>
                </ion-item>
              </ng-container>
            </ion-list>
          </div>
        </div>
        <ion-item>
          <ion-button color="light" (click)="addTextElement()"> + Add text </ion-button>
        </ion-item>
      </ion-list>
    </ng-container>

    <ng-container *ngSwitchCase="'survey'">
      <ion-list class="slack-block-form">
        <ion-item [disabled]="true">
          <ion-label position="stacked">Survey type</ion-label>
          <span *ngIf="block.survey.type === 'nps'"> NPS score </span>
          <span *ngIf="block.survey.type === 'csat'"> CSAT score </span>
        </ion-item>
        <ion-item [hidden]="block.survey.buttonLayout === 'inline'" [disabled]="true">
          <ion-label position="stacked">Button label</ion-label>
          <ion-input
            [(ngModel)]="block.survey.buttonLabel"
            type="text"
            [maxlength]="2000"
            required
            [disabled]="block.survey_id"
          ></ion-input>
        </ion-item>
        <ion-item [disabled]="true">
          <ion-label position="stacked">Message after submission</ion-label>
          <ion-input
            [(ngModel)]="block.survey.postSubmissionMessage"
            type="text"
            [maxlength]="2000"
            required
            [disabled]="block.survey_id"
          ></ion-input>
        </ion-item>
        <ion-item [hidden]="block.survey.buttonLayout === 'inline'">
          <ion-button (click)="viewSurveyPreview(block)">
            View pop-up preview <ion-icon name="open-outline" class="icon-padded-left"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-container>
  </ng-container>
</div>
