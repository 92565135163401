import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';
import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';

import { PLACEHOLDER_IMAGE_SRC } from 'src/app/constants';
import { MessageService } from 'src/services/message.service';
import { UploadService } from 'src/services/upload.service';

import { GiphyModalComponent } from '../../giphy-modal/giphy-modal.component';
import { GiphyModalModule } from '../../giphy-modal/giphy-modal.module';
import { ImageModalComponent } from '../../image-modal/image-modal.component';
import { Block, isButtons, isContext, isMrkdwn, isSection, isText, SurveyBlock } from '../common';
import { SlackMarkdown } from '../slack-markdown';
import { SlackSurveyPreviewComponent } from '../slack-survey-preview/slack-survey-preview.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, AngularEditorModule, GiphyModalModule],
  selector: 'app-slack-block-edit',
  templateUrl: './slack-block-edit.component.html',
  styleUrls: ['./slack-block-edit.component.scss'],
})
export class SlackBlockEditComponent implements OnInit {
  @Input() block: any;
  @Input() slackMarkdown: SlackMarkdown;
  @Output() blockChanged = new EventEmitter<Block>();

  constructor(
    private modalCtrl: ModalController,
    private uploadSrvc: UploadService,
    private loadingCtrl: LoadingController,
    private msgSrvc: MessageService,
  ) {}

  public error?: string;
  public htmlStrings: Record<number, string> = {};

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'underline',
        'fontName',
        'subscript',
        'superscript',
        'justifyFull',
        'justifyCenter',
        'justifyLeft',
        'justifyRight',
        'indent',
        'outdent',
        'heading',
      ],
      [
        'textColor',
        'fontSize',
        'insertHorizontalRule',
        'backgroundColor',
        'removeFormat',
        'insertImage',
        'insertVideo',
        'toggleEditorMode',
      ],
    ],
    minHeight: '100px',
    width: '100%',
  };

  public editorConfigContext: AngularEditorConfig = {
    ...this.editorConfig,
    minHeight: '44px',
  };

  ngOnInit() {
    this.setHtmlStrings();
  }

  setHtmlStrings() {
    this.htmlStrings = {};

    if (isSection(this.block) && isMrkdwn(this.block.text)) {
      this.htmlStrings[0] = this.slackMarkdown.toHtml(this.block.text.text);
    } else if (isContext(this.block)) {
      for (const [i, element] of this.block.elements.entries()) {
        if (isText(element) && isMrkdwn(element)) {
          this.htmlStrings[i] = this.slackMarkdown.toHtml(element.text);
        }
      }
    }
  }

  onSectionHtmlChange($event) {
    if (isSection(this.block) && isMrkdwn(this.block.text)) {
      this.block.text.text = this.slackMarkdown
        .toMd(
          $event.replace(
            /\<div\>(?:\<i\>|\<b\>|\<strike\>)+\<br\>(?:\<\/b\>|\<\/i\>|\<\/strike\>)+\<\/div\>/g,
            '<div><br></div>',
          ),
        )
        .replace(/\n\n/g, '\n');
    }

    this.blockChanged.emit(this.block);
  }

  toggleImageAccessory() {
    if (isSection(this.block)) {
      if (this.block.accessory) {
        delete this.block.accessory;
      } else {
        this.block.accessory = {
          type: 'image',
          image_url: PLACEHOLDER_IMAGE_SRC,
          alt_text: 'My image',
        };
      }
    }
  }

  async addImageAccessory() {
    const isAddingSectionAccessoryImage = isSection(this.block);
    const componentProps: any = {
      searchValue: '',
      helperText: '',
      showStockPhotos: true,
    };

    if (isAddingSectionAccessoryImage) {
      componentProps.aspectRatio = 1;
    }
    const modal = await this.modalCtrl.create({
      component: ImageModalComponent,
      componentProps,
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    // if backdrop is clicked and no data passed
    if (!data || !data.uploadFile) {
      return;
    }

    const loader = await this.loadingCtrl.create({
      message: 'Uploading...',
      spinner: 'crescent',
      duration: 10000,
    });
    loader.present();

    this.uploadSrvc
      .upload([data.uploadFile], `slack`)
      .then(() => {
        loader.dismiss();
        const imageUrl = data.uploadFile.url;

        if (isAddingSectionAccessoryImage) {
          this.block.accessory = {
            type: 'image',
            image_url: imageUrl,
            alt_text: 'My image',
          };
        } else {
          this.block.image_url = imageUrl;
        }
      })
      .catch((err) => {
        loader.dismiss();
        this.msgSrvc.show(err.message);
      });
  }

  async addGif() {
    const modal = await this.modalCtrl.create({
      component: GiphyModalComponent,
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data.url) {
      this.block.image_url = data.url;
    }
  }

  removeAccessory() {
    if (isSection(this.block)) {
      this.block.accessory = undefined;
    }
  }

  addTextElement() {
    if (isContext(this.block)) {
      this.block.elements.push({
        type: 'mrkdwn',
        text: 'Context goes here',
        verbatim: true,
      });
    }
  }

  addImageElement() {
    if (isContext(this.block)) {
      this.block.elements.push({
        type: 'image',
        image_url: PLACEHOLDER_IMAGE_SRC,
        alt_text: '',
      });
    }
  }

  addButtonElement() {
    this.block.elements.push({
      type: 'button',
      text: {
        type: 'plain_text',
        text: 'Button label',
        emoji: true,
      },
      value: 'click_me_123',
      url: 'https://',
    });
  }

  removeElement(i: number) {
    if (isContext(this.block) || isButtons(this.block)) {
      this.block.elements.splice(i, 1);

      this.setHtmlStrings();
    }
  }

  getElementTitle(element: any) {
    if (element.type === 'image') {
      return 'Image';
    }

    return 'Text';
  }

  onContextHtmlChange(i: number, $event) {
    if (isContext(this.block)) {
      const element = this.block.elements[i];

      if (isText(element) && isMrkdwn(element)) {
        element.text = this.slackMarkdown.toMd($event);
      }
    }

    this.blockChanged.emit(this.block);
  }

  async viewSurveyPreview(block: SurveyBlock) {
    const surveyPreviewModal = await this.modalCtrl.create({
      component: SlackSurveyPreviewComponent,
      componentProps: {
        survey: block.survey,
      },
    });
    await surveyPreviewModal.present();
  }
}
