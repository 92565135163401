import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';

import { OUTBOUND_RECIPIENT_TYPE, PLACEHOLDER_IMAGE_SRC, routes, TEMPLATE_TYPE } from 'src/app/constants';
import { ListQueryResult, Organizer, Profile, Template } from 'src/models';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { CustomersService } from 'src/services/customers.service';
import { MessageService } from 'src/services/message.service';
import { UsersService } from 'src/services/users.service';

import { ActionDropdownComponent, ActionDropdownType } from '../action-dropdown/action-dropdown.component';
import { UpsertSurveyModalComponent } from '../upsert-survey-modal/upsert-survey-modal.component';

import { Block, BLOCK_TYPES, BLOCK_NAMES, SendAs } from './common';
import { SlackMarkdown } from './slack-markdown';

@Component({
  selector: 'app-slack-blocks',
  templateUrl: './slack-blocks.component.html',
  styleUrls: ['./slack-blocks.component.scss'],
})
export class SlackBlocksComponent implements OnInit {
  @Input() public blocks: Block[] = [];
  @Input() public communityId: string;
  @Input() public disabled: boolean;
  @Input() public sendAs: SendAs | null = null;
  @Output() blocksChanged = new EventEmitter<Block[]>();
  @Output() sendAsChanged = new EventEmitter<SendAs | null>();

  isLoadingTemplates = false;
  isLoadingSurveys = false;
  templates: Template[] = [];
  surveys: any[] = [];
  community: Organizer;
  sendAsName: string;
  sendAsImage: string;

  public slackMarkdown = new SlackMarkdown();

  constructor(
    private popoverCtrl: PopoverController,
    private navController: NavController,
    private apiService: ApiService,
    private msgSrvc: MessageService,
    public authService: AuthService,
    private customersService: CustomersService,
    private usersService: UsersService,
    private analyticsService: AnalyticsService,
    private modalCtrl: ModalController,
  ) {}

  async ngOnInit() {
    if (!this.blocks?.length) {
      this.addSectionBlock();
    }

    if (!this.sendAs) {
      this.sendAs = {
        type: OUTBOUND_RECIPIENT_TYPE.USER,
        id: this.authService.userId,
      };
      this.sendAsName = this.authService.profile.name;
      this.sendAsImage = this.authService.profile.photo;
    }

    await Promise.all([this.getTemplates(), this.getSurveys()]);

    await this.getCommunity();
    await this.getSend();
  }

  async getCommunity() {
    if (this.communityId) {
      this.community = (await this.customersService.get(this.communityId)) as Organizer;
    }
  }

  async getSend() {
    if (this.sendAs?.type === OUTBOUND_RECIPIENT_TYPE.USER) {
      const sendAsUser = (await this.usersService.getUser(this.sendAs.id)) as Profile;

      this.sendAsName = sendAsUser.name;
      this.sendAsImage = sendAsUser.photo;
    } else if (this.sendAs?.type === OUTBOUND_RECIPIENT_TYPE.COMMUNITY) {
      this.sendAsName = this.community.name;
    }
  }

  getBlockName(block: Block) {
    return BLOCK_NAMES[block.type];
  }

  addSectionBlock() {
    this.blocks.push({
      type: BLOCK_TYPES.SECTION,
      text: {
        type: 'mrkdwn',
        text: 'Sample content here',
        verbatim: true,
      },
    });

    this.analyticsService.trackEvent('Slack Block Editor', 'Add Section Block');

    this.blockChanged();
  }

  addImageBlock() {
    this.blocks.push({
      type: BLOCK_TYPES.IMAGE,
      image_url: PLACEHOLDER_IMAGE_SRC,
      alt_text: '',
    });

    this.analyticsService.trackEvent('Slack Block Editor', 'Add Image Block');
  }

  addDividerBlock() {
    this.blocks.push({
      type: BLOCK_TYPES.DIVIDER,
    });

    this.analyticsService.trackEvent('Slack Block Editor', 'Add Divider Block');
  }

  addHeaderBlock() {
    this.blocks.push({
      type: BLOCK_TYPES.HEADER,
      text: {
        type: 'plain_text',
        text: 'Header here',
        emoji: true,
      },
    });

    this.analyticsService.trackEvent('Slack Block Editor', 'Add Header Block');

    this.blockChanged();
  }

  addContextBlock() {
    this.blocks.push({
      type: BLOCK_TYPES.CONTEXT,
      elements: [
        {
          type: 'mrkdwn',
          text: 'Context goes here',
          verbatim: true,
        },
      ],
    });

    this.analyticsService.trackEvent('Slack Block Editor', 'Add Context Block');
  }

  addButtonsBlock() {
    this.blocks.push({
      type: BLOCK_TYPES.BUTTONS,
      elements: [
        {
          type: 'button',
          text: {
            type: 'plain_text',
            text: 'Button label',
            emoji: true,
          },
          value: 'click_me_123',
          url: 'https://',
        },
      ],
    });

    this.analyticsService.trackEvent('Slack Block Editor', 'Add Buttons Block');
  }

  checkIfAlreadyHasSurveyBlock() {
    let hasSurveyBlock = false;

    this.blocks.forEach((block) => {
      if (block.type === BLOCK_TYPES.SURVEY) {
        hasSurveyBlock = true;
      }
    });

    if (hasSurveyBlock) {
      this.msgSrvc.show('You can only include one survey per message');
      return true;
    }

    return false;
  }

  async addSurveyBlock() {
    if (this.checkIfAlreadyHasSurveyBlock()) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: UpsertSurveyModalComponent,
      componentProps: {
        shouldSave: false,
      },
    });

    modal.present();

    modal.onDidDismiss().then(({ data }) => {
      if (data) {
        this.blocks.push({
          type: BLOCK_TYPES.SURVEY,
          elements: [],
          survey: {
            ...data.style,
            type: data.type,
          },
        });

        this.analyticsService.trackEvent('Slack Block Editor', 'Add New Survey Block');
      }
    });
  }

  removeBlock(i) {
    this.blocks.splice(i, 1);
    this.blockChanged();
  }

  duplicateBlock(i) {
    this.blocks.splice(i + 1, 0, JSON.parse(JSON.stringify(this.blocks[i])));
  }

  onReorder({ detail }) {
    const { from, to } = detail;

    this.blocks.splice(to, 0, this.blocks.splice(from, 1)[0]);

    detail.complete();

    this.blockChanged();
  }

  async getTemplates() {
    this.isLoadingTemplates = true;

    try {
      const response = (await this.apiService.getPromise(`/templates`, {
        communityId: this.communityId || '',
        type: TEMPLATE_TYPE.SLACK,
      })) as ListQueryResult;
      this.templates = response.rows;
      this.isLoadingTemplates = false;
    } catch (err) {
      await this.msgSrvc.showError(err);
      this.isLoadingTemplates = false;
    }
  }

  async getSurveys() {
    this.isLoadingSurveys = true;

    try {
      const response: any = await this.apiService.postPromise(`/surveys/list`, {});

      this.surveys = response.data;
    } catch (err) {
      await this.msgSrvc.showError(err);
    } finally {
      this.isLoadingSurveys = false;
    }
  }

  async applyTemplate() {
    const options: ActionDropdownType[] = this.templates.map((temp) => {
      return {
        label: temp.name,
      };
    });

    if (!options.length) {
      options.push({
        label: 'Create a template',
        icon: 'add-circle-outline',
      });
    }

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (idx: number, _label: string) => {
          popover.dismiss();

          if (!this.templates.length) {
            const route = this.communityId
              ? `${routes.DASHBOARD}/${routes.CUSTOMERS}/${this.communityId}/templates`
              : `${routes.DASHBOARD}/outbound/templates`;

            this.navController.navigateRoot(route);

            return;
          }

          const template = this.templates[idx];

          this.blocks.splice(0, this.blocks.length, ...template.chunks);

          this.analyticsService.trackEvent('Slack Block Editor', 'Apply Template Blocks');

          this.blockChanged();
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  async applySurvey() {
    const options: ActionDropdownType[] = this.surveys.map((s) => {
      return {
        label: s.name,
      };
    });

    options.push({
      label: 'Create new survey',
      icon: 'add-circle-outline',
    });

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (idx: number, _label: string) => {
          popover.dismiss();

          if (idx === this.surveys.length) {
            this.addSurveyBlock();
          } else {
            if (this.checkIfAlreadyHasSurveyBlock()) {
              return;
            }

            const survey = this.surveys[idx];

            this.blocks.push({
              type: BLOCK_TYPES.SURVEY,
              elements: [],
              survey_id: survey.id,
              survey: {
                ...survey.style,
                type: survey.type,
              },
            });

            this.analyticsService.trackEvent('Slack Block Editor', 'Add Existing Survey Block');

            this.blockChanged();
          }
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  blockChanged() {
    this.blocksChanged.emit(this.blocks);
  }
}
